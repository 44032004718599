import * as React from 'react';
// @ts-ignore
import * as Klaro from 'klaro/dist/klaro-no-css';
import './LhCookiesConsentManager.sass';
import {LhCookiesConsentManagerProps} from "./LhCookiesConsentManagerTypes";
import {useState} from "react";
import LhIcon from "../LhIcon";

declare global {
  interface Window {
    klaro: any;
    klaroConfig: any;
  }
}

const LhCookiesConsentManager : React.FunctionComponent<LhCookiesConsentManagerProps> = function ({ apps, privacyPolicyUrl, translations, cookieExpiresAfterDays = 120, cookieName = 'gdprConsent', editButtonStyle}) {
  const [displayEditButton, setDisplayEditButton] = useState(false);

  const defaultTranslations = {
    fr: {
      acceptAll: 'Tout accepter',
      acceptSelected: 'Accepter la sélection',
      decline: 'Tout refuser',
      consentNotice: {
        description: `Ce site utilise des cookies destinés à améliorer la navigation et adapter le contenu en mesurant le nombre de visites et de pages vues. <a target="_blank" href='${privacyPolicyUrl}' class='privacy-policy'>En savoir plus</a>`,
        learnMore: 'Personnaliser',
      },
      consentModal: {
        title: 'Panneau de gestion des cookies',
        description: 'En autorisant ces services tiers, vous acceptez le dépôt et la lecture de cookies et l’utilisation de technologies de suivi nécéssaires à leur bon fonctionnement.',
      },
      ok: 'Accepter',
      googleAnalytics: {
        title: 'Google Analytics',
        description: 'Ce service permet de recolter des données à des fins statistiques.',
      },
      intercom: {
        title: 'Intercom',
        description: 'Ce service permet le bon fonctionnement du livechat.',
      },
      facebook: {
        title: 'Facebook',
        description: 'Ce service permet de recolter des données à des fins marketing.',
      },
      purposes: {
        analytics: {
          title: 'Mesure d\'audience',
          description: 'Les services de mesure d\'audience permettent de générer des statistiques de fréquentation utiles à l\'amélioration du site.',
        },
        livechat: {
          title: 'Livechat',
          description: 'Les services de livechat permettent la mise en place d\'une chatbox sur le site.',
        },
        marketing: {
          title: 'Marketing',
          description: 'Ces cookies sont utilisés à des fins marketing.',
        },
      },
      service: {
        disableAll: {
          description: 'Utilisez ce switch pour activer/désactiver tous les services',
        },
      },
    },
  };

  const config = {
    lang: 'fr',
    elementID: 'lh-consent',
    cookieName,
    cookieExpiresAfterDays,
    disablePoweredBy: true,
    htmlTexts: true,
    acceptAll: true,
    translations: translations || defaultTranslations,
    apps,
    callback: () => {
      setTimeout(() => {
        if (!document.querySelector('.cookie-notice')) {
          setDisplayEditButton(true);
        }
      }, 0.1);
    },
  };

  window.klaro = Klaro;
  window.klaroConfig = config;
  Klaro.setup(config);

  return (
    <button
      className="lh-consent-edit"
      style={{
        ...editButtonStyle,
        display: displayEditButton ? 'block' : 'none',
      }}
      onClick={() => Klaro.show()}
    >
      <LhIcon name="lh-Settings-Outlined" />
    </button>
  )
};

export default LhCookiesConsentManager;
