import React, {PropsWithChildren, useEffect} from 'react'
import {LhCarouselProps} from './LhCarouseltypes'
import Slider from "react-slick";
import './LhCarousel.sass'

const LhCarousel: React.FC<PropsWithChildren<LhCarouselProps>> = ({
                                                                                              children,
  position,
                                                                                            }) => {
  // @ts-ignore
  let slider = null

  useEffect(() => {
    // @ts-ignore
    slider.slickGoTo(position)
  }, [position]);

  return (
    <div className="carousel">
      <Slider swipe={false} draggable={false} arrows={false} infinite={true} autoplay={false} initialSlide={position} ref={c => (slider = c)} >
        {children}
      </Slider>
    </div>
  )
}


export default LhCarousel
