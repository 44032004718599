import React, {PropsWithChildren} from 'react'
import {LhCardProps} from './LhCardtypes'
import './LhCard.sass'
import cx from 'classnames'
import {motion} from 'framer-motion'
import Skeleton from "react-loading-skeleton";
// @ts-ignore
import TextTransition, {presets} from 'react-text-transition';
import LhNumber from "../LhNumber";

const LhCard: React.FC<PropsWithChildren<LhCardProps>> = ({
                                                            title,
                                                            number= 0,
                                                            percent,
                                                            isPrice = false,
                                                            loading = false,
                                                            hasContent = false,
  showNumber= true,
                                                            children,
  style
                                                          }) =>
  (
    <motion.div
      className='card'
      animate={{
        scale: 1
      }}
      initial={{
        scale: 0
      }}
      transition={{type: 'spring', damping: 30, stiffness: 500}}
    >
      <div className='card__header'>
        <div className='card__header__up'>
          <div className='card__header__up__title'>{!loading ? title : <Skeleton width={100}/>}</div>
          <div
            className={cx(
              'card__header__up__percent',
              {green: percent && percent >= 0},
              {red: percent && percent < 0}
            )}
          >
            {loading ? <Skeleton width={30}/> : (
              percent ? percent >= 0 ? `+${percent}%` : `${percent}%` : null
            )}
          </div>
        </div>
        {showNumber && (
          <div className='card__header__down'>
            {loading ? (
              <Skeleton width={200}/>
            ) : (
              <LhNumber number={number} isPrice={isPrice} />
            )}
          </div>
        )}
      </div>
      {hasContent && (
        <div className='card__content' style={style}>{(!loading && children) ? children :
          <Skeleton height={100}/>}</div>
      )}
    </motion.div>
  )

export default LhCard
