import React from 'react'
import { LhCalendarProps } from './LhCalendartypes'
import './LhCalendar.sass'
import Calendar from 'react-calendar'
import LhIcon from '../LhIcon'

const LhCalendar: React.FC<LhCalendarProps> = ({ range = false, onChange, value }) => {
  return (
    <Calendar
      onChange={onChange}
      className='calendar'
      selectRange={range}
      view='month'
      locale='fr-FR'
      nextLabel={<LhIcon name='lh-Arrow-Right' />}
      prevLabel={<LhIcon name='lh-Arrow-Left' />}
      formatShortWeekday={(_locale, date) =>
        ['D', 'L', 'M', 'M', 'J', 'V', 'S'][date.getDay()]
      }
      value={value}
    />
  )
}

export default LhCalendar
