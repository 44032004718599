import React, { PropsWithChildren } from 'react'
import { LhNavbarItemsProps } from './LhNavbarItemtypes'
import LhNavbarLink from '../LhNavbarLink/LhNavbarLink'
import LhIcon from '../LhIcon'
import './LhNavbarItem.sass'

const LhNavbarItem: React.FunctionComponent<
  PropsWithChildren<LhNavbarItemsProps>
> = ({ icon, name, link, isActive, dropdown, outsideDomain = false }) => (
  <li className='lh-navbar__list-item'>
    {dropdown ? (
      <div className='lh-navbar__list-anchor dropdown'>
        <LhIcon name={icon} />
        <i className='lh lh-Arrow-Chevron-Down' />
        <div className='dropdown-content'>
          <ul>
            {dropdown.map((item, index) => (
              <li key={`item-dropdown-${item.name}-${index}`}>
                <LhNavbarLink to={item.link} outsideDomain={outsideDomain}>
                  <LhIcon name={item.icon} />
                  <span>{item.name}</span>
                </LhNavbarLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    ) : (
      <LhNavbarLink to={link} isActive={isActive} outsideDomain={outsideDomain}>
        <LhIcon name={icon} />
        <span>{name}</span>
      </LhNavbarLink>
    )}
  </li>
)

export default LhNavbarItem
