import React from 'react'
import { LhIconProps } from './LhIcontypes'
import './LhIcon.sass'
import cx from 'classnames'

const LhIcon: React.FC<LhIconProps> = ({
  name,
  size,
  color,
  disabled = false,
  className
}) => (
  <i
    className={cx('icon', 'lh', name, className, { disabled })}
    style={{
      fontSize: size,
      color
    }}
  />
)

export default LhIcon
