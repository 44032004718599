import React, {useEffect, useState} from 'react'
import { LhPlayerProps } from './LhPlayertypes'
import './LhPlayer.sass'
import LhIcon from '../LhIcon'

const LhPlayer: React.FC<LhPlayerProps> = ({ nbSecond, slides, currentSlide, goNextSlide, goPreviousSlide }) => {
  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(true);

  function toggle() {
    setIsActive(!isActive);
  }

  useEffect(() => {
    let interval: NodeJS.Timeout = setInterval(() => {}, 0)
    if (isActive) {

      interval = setInterval(() => {
        if (seconds >= nbSecond) {
          setSeconds(0)
          goNextSlide()
        } else {
          setSeconds(seconds => seconds + 0.1);
        }
      }, 100);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  function goToNextSlide() {
    setSeconds(0)
    goNextSlide()
  }

  function goToPreviousSlide() {
    setSeconds(0)
    goPreviousSlide()
  }

  return (
    <div className='player'>
      <div className='player__buttons'>
        <button className='player__buttons__button' onClick={toggle}>
          {isActive ? (
            <LhIcon name='lh-Player-Pause' />
          ) : (
            <LhIcon name='lh-Play-Circle' />
          )}
        </button>
        <button className='player__buttons__button' onClick={goToPreviousSlide}>
          <LhIcon name='lh-Player-Previous' disabled={currentSlide === 0} />
        </button>
        <button className='player__buttons__button' onClick={goToNextSlide}>
          <LhIcon
            name='lh-Player-Next'
            disabled={currentSlide === slides - 1}
          />
        </button>
      </div>
      <div className='player__progress-bar'>
        <div
          className='player__progress-bar__inner'
          style={{
            width: `${((100/nbSecond) * seconds)}%`
          }}
        />
      </div>
    </div>
  )
}

export default LhPlayer
