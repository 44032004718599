import React, {PropsWithChildren, useEffect, useState} from 'react'
import './LhNumber.sass'
import {formatNumber, formatPrice} from "../Helper";
// @ts-ignore
import TextTransition, {presets} from 'react-text-transition';
import classNames from "classnames";
import {LhNumberProps} from "./LhNumbertypes";

const LhNumber: React.FC<PropsWithChildren<LhNumberProps>> = ({
                                                                number,
                                                                isPrice = false,
  className
                                                              }) => {
  const [prevNumber, setPrevNumber] = useState<number>(0)
  const [newNumber, setNewNumber] = useState<number>(number || 0)
  const [colorTransition, setColorTransition] = useState(false)

  useEffect(() => {
      setColorTransition(true)
      setPrevNumber(newNumber)
      setNewNumber(number)
      setTimeout(() => setColorTransition(false), 1000)
  }, [number])

  return (
      <div
        className={classNames('number', className)}>
        {isPrice ? formatPrice(newNumber).split("").map((n, i) => (
          <TextTransition
            key={i}
            text={n}
            direction="down"
            noOverflow
            inline
            springConfig={presets.default}
            className={classNames({red: newNumber < prevNumber && colorTransition}, {green: newNumber > prevNumber && colorTransition})}
          />
        )) : formatNumber(newNumber).split("").map((n, i) => (
          <TextTransition
            key={i}
            text={n}
            className="big"
            direction="down"
            noOverflow
            inline
            springConfig={presets.default}
          />
        ))}
    </div>
  )
}

export default LhNumber
