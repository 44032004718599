import React, { PropsWithChildren } from 'react'
import { motion } from 'framer-motion'
import cx from 'classnames'
import { LhButtonProps } from './LhButtontypes'
import './LhButton.sass'
import LhIcon from '../LhIcon'

const LhButton: React.FC<PropsWithChildren<LhButtonProps>> = ({
  full,
  color,
  disabled,
  onClick,
  loading,
  children
}) => {
  const buttonVariants = {
    show: { opacity: 1 },
    hide: { opacity: 0 }
  }

  const labelVariants = {
    show: { marginLeft: 25 },
    hide: { marginLeft: 0 }
  }

  return (
    <motion.button
      className={cx('button', color, { full }, { disabled })}
      disabled={disabled}
      onClick={onClick}
      layout
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
    >
      <motion.div className='button__inner'>
        <motion.div
          className='button__inner__loader'
          variants={buttonVariants}
          animate={loading ? 'show' : 'hide'}
          transition={{ ease: 'easeIn', duration: 0.2 }}
        >
          <motion.div
            className='button__inner__loader__wrapper'
            animate={{
              rotate: 360
            }}
            transition={{ duration: 2, loop: Infinity, ease: 'linear' }}
          >
            <LhIcon name='lh-Loader' />
          </motion.div>
        </motion.div>
        <motion.span
          variants={labelVariants}
          animate={loading ? 'show' : 'hide'}
          className='button__label'
          initial={{
            marginLeft: 0
          }}
        >
          {children}
        </motion.span>
      </motion.div>
    </motion.button>
  )
}

export default LhButton
