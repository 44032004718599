import React, { PropsWithChildren } from 'react'
import { NavLink } from 'react-router-dom'
import { LhNavbarLinkProps } from './LhNavbarLink.types'

const LhNavbarLink: React.FunctionComponent<
  PropsWithChildren<LhNavbarLinkProps>
> = ({ isActive, children, to, outsideDomain = false }) => {
  if (outsideDomain) {
    return (
      <a href={to} className='lh-navbar__list-anchor'>
        {children}
      </a>
    )
  }

  return (
    <NavLink
      className='lh-navbar__list-anchor'
      activeClassName='selected'
      isActive={isActive}
      to={to}
    >
      {children}
    </NavLink>
  )
}

export default LhNavbarLink
