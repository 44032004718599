import React from 'react'

import {LhNavbarProps} from './LhNavbar.types'

import './LhNavbar.sass'
import LhNavbarItem from '../LhNavbarItem/LhNavbarItem'

const LhNavbar: React.FC<LhNavbarProps> = ({leftItems, rightItems, logoLinkUrl, logoIconUrl}) => (
  <div data-testid='test-component' className='lh-navbar'>
    <ul className='lh-navbar__list top-list'>
      {logoIconUrl && (
        <li className="lh-navbar__list-item logo">
          <a className="lh-navbar__list-anchor" href={logoLinkUrl}>
            <img className="lh-navbar__icon" src={logoIconUrl} alt=""/>
          </a>
        </li>
      )}
      {leftItems.map((item, index) => (
        <LhNavbarItem
          key={`lh-navbar-left-items-${item.name}-${index}`}
          name={item.name}
          icon={item.icon}
          link={item.link}
          isActive={item.isActive}
          dropdown={item.dropdown}
          outsideDomain={item.outsideDomain}
        />
      ))}
    </ul>
    <ul className='lh-navbar__list bottom-list'>
      {rightItems.map((item, index) => (
        <LhNavbarItem
          key={`lh-navbar-left-items-${item.name}-${index}`}
          name={item.name}
          icon={item.icon}
          link={item.link}
          isActive={item.isActive}
          dropdown={item.dropdown}
          outsideDomain={item.outsideDomain}
        />
      ))}
    </ul>
  </div>
)

export default LhNavbar
