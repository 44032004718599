const formatPrice = (number: number) => {
  return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(number)
}

const formatNumber = (number: number) => {
  return new Intl.NumberFormat('fr-FR').format(number)
}

export {
  formatPrice,
  formatNumber
}
