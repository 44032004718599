import React from 'react'
import { motion } from 'framer-motion'
import { LhPodiumProps } from './LhPodiumtypes'
import './LhPodium.sass'
import LhIcon from '../LhIcon'
import Skeleton from "react-loading-skeleton";

const LhPodium: React.FC<LhPodiumProps> = ({ items, icon= 'lh-Medal', prefix, hasRank = false }) => {
  const spring = {
    type: 'spring',
    damping: 25,
    stiffness: 120
  }

  return (
    <div className='podium'>
      <ul className='podium__list'>
        {items.length > 0 ? (
          items
            .map((item, index) => {
              return (
                <motion.li
                  className='podium__list__item'
                  key={`${prefix}-podium-${item.label}-${item.id}`}
                  layout
                  transition={spring}
                >
                  <div className='podium__list__item__label'>
                    <img src={item.picture} alt='' />
                    <span>{item.label}</span>
                  </div>
                  <span className='podium__list__item__number'>
                  {item.value}
                </span>
                  <div className='podium__list__item__order'>
                    <span>{hasRank ? item.rank : index + 1}</span>
                    <LhIcon name={icon} />
                  </div>
                </motion.li>
              )
            })
        ) : (
          <Skeleton width='100%' height={100} />
        )}
      </ul>
    </div>
  )
}

export default LhPodium
