import React, { useState } from 'react'
import { LhDatePickerProps } from './LhDatePickertypes'
import './LhDatePicker.sass'
import LhIcon from '../LhIcon'
import { motion } from 'framer-motion'
import LhCalendar from '../LhCalendar'
import LhButton from '../LhButton'
import cx from 'classnames'

const LhDatePicker: React.FC<LhDatePickerProps> = ({ onChange }) => {
  const [show, setShow] = useState<boolean>(false)
  const [selected, setSelected] = useState<string | Date[]>('live')
  const [calendarValue, setCalendarValue] = useState<Date | Date[] | null>(null)
  const options = [
    { label: 'En direct', value: 'live' },
    { label: '7 derniers jours', value: 'weekly' },
    { label: '30 derniers jours', value: 'monthly' },
    { label: 'Depuis la création', value: 'creation' }
  ]
  const dropdownVariants = {
    open: { opacity: 1, y: 0 },
    closed: { opacity: 0, y: '-115%' }
  }

  const chevronVariants = {
    open: { rotate: 180 },
    closed: { rotate: 0 }
  }

  const handleListClick = (value: string) => {
    setCalendarValue(null)
    setSelected(value)
  }

  const handleCalendarChange = (value: Date | Date[]) => {
    setCalendarValue(value)
    if (Array.isArray(value)) {
      setSelected(value)
    }
  }

  return (
    <div className='date-picker'>
      <div className='date-picker__select' onClick={() => setShow(!show)}>
        <LhIcon
          name={selected === 'live' ? 'lh-Flash' : 'lh-Calendar'}
          className={selected === 'live' ? 'flash' : 'calendar'}
        />
        <span>
          {typeof selected === 'string'
            ? options.find((option) => option.value === selected)?.label
            : `${selected[0].toLocaleDateString()} - ${selected[1].toLocaleDateString()}`}
        </span>
        <motion.div
          animate={show ? 'open' : 'closed'}
          variants={chevronVariants}
          className='date-picker__select__chevron'
        >
          <LhIcon name='lh-Arrow-Chevron-Down' />
        </motion.div>
      </div>
      <motion.div
        className='date-picker__dropdown'
        animate={show ? 'open' : 'closed'}
        variants={dropdownVariants}
        initial={{ opacity: 0, y: '-100%' }}
      >
        <div className='date-picker__dropdown__content'>
          <ul className='date-picker__dropdown__content__list'>
            {options.map((option) => (
              <li
                className={cx({ selected: option.value === selected })}
                onClick={() => handleListClick(option.value)}
              >
                {option.label}
              </li>
            ))}
          </ul>
          <div className='date-picker__dropdown__content__calendar'>
            <LhCalendar onChange={handleCalendarChange} range value={calendarValue} />
          </div>
        </div>
        <div className='date-picker__dropdown__footer'>
          <div className='date-picker__dropdown__footer__content'>
            <LhButton color='secondary' onClick={() => setShow(false)}>
              Annuler
            </LhButton>
            <LhButton color='primary' onClick={() => {
              onChange(selected)
              setShow(false)
            }}>
              Valider
            </LhButton>
          </div>
        </div>
      </motion.div>
    </div>
  )
}

export default LhDatePicker
